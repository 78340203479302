<template>
  <div>
    <v-card tile>
      <v-container fill-height v-if="singleItemLoading">
        <v-layout row justify-center align-center>
          <v-progress-circular indeterminate :size="100" :width="7" color="purple"></v-progress-circular>
        </v-layout>
      </v-container>
      <v-toolbar
          v-show="!singleItemLoading"
          flat
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("PAGES.MEMBER_GROUPS.DIALOG.MANAGE.TITLE") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="save()"
          >
            {{ $t("COMMON.DIALOG.SAVE") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-show="!singleItemLoading">
        <v-form>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-text-field
                    v-model="singleItem.id"
                    :disabled=true
                    filled
                    color="blue-grey lighten-2"
                    :label='$t("PAGES.MEMBER_GROUPS.DIALOG.MANAGE.ID")'
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="singleItem.group_name"
                    filled
                    color="blue-grey lighten-2"
                    :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.GROUP_NAME")'
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    :value="(singleItem.createdAt!= null)?new Date(singleItem.createdAt).toLocaleString():'---'"
                    :disabled=true
                    filled
                    color="blue-grey lighten-2"
                    :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.CREATED_AT")'
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    :value="(singleItem.updatedAt!= null)?new Date(singleItem.updatedAt).toLocaleString():'---'"
                    :disabled=true
                    filled
                    color="blue-grey lighten-2"
                    :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.UPDATED_AT")'
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-checkbox v-model="singleItem.is_admin"
                            :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.ADMIN")'></v-checkbox>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-checkbox v-model="singleItem.is_banned"
                            :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.BANNED")'></v-checkbox>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-checkbox v-model="singleItem.delete_users"
                            :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.DELETE_USERS")'></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-checkbox v-model="singleItem.allow_import"
                            :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.ALLOW_IMPORT")'></v-checkbox>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-checkbox v-model="singleItem.allow_export"
                            :label='$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.ALLOW_EXPORT")'></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </div>
</template>
<script>

// import {mapGetters} from "vuex";
import {UPDATE_MEMBER_GROUP_DATA} from "@/core/services/store/member_groups.module";

export default {
  data() {
    return {
      filters: {
        "query": [],
        "filters": {},
        "sort": {},
        "page": 1
      },
      numberRule: [
        v => {
          // if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
          return this.$t('PAGES.MEMBER_GROUPS.DIALOG.MANAGE.ERROR_TOTAL_ALLOW_TRIAL');
        }],
      // [v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || this.$t('PAGES.MEMBER_GROUPS.DIALOG.MANAGE.ERROR_TOTAL_ALLOW_TRIAL')],
      // single_item_loanding: false,
      member_group: {}
    }
  },
  name: "ManageGroup",
  props: ['single-item', 'close-dialog', 'single-item-loading'],
  computed: {},
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    save() {
      this.member_group = Object.assign({}, this.member_group, this.singleItem);
      this.$delete(this.member_group, 'updatedAt');
      this.$delete(this.member_group, 'createdAt');

      this.singleItemLoading = true
      this.$emit('dialogLoading', true);
      this.$store.dispatch(UPDATE_MEMBER_GROUP_DATA, this.member_group);
      setTimeout(() => (this.$emit('dialogLoading', false)), 2000);
      // setTimeout(() => (this.$emit('close-dialog')), 2000);
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
        ;
      } else {
        return true;
      }
    }
  },
  mounted() {
  },
  watch: {
    singleItemLoading(val) {
      if (!val) return

      setTimeout(() => (this.singleItemLoading = false), 2000);
    },
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <template>
        <v-card>
          <v-card-title>
            {{ $t("PAGES.MEMBER_GROUPS.LIST.TABLE.TITLE") }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="tableData.search"
              append-icon="search"
              :label="$t('PAGES.MEMBER_GROUPS.LIST.TABLE.FILTER')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            fixed-header
            :headers="tableData.headers"
            loading-text="Loading... Please wait"
            :items="dataTableList.data"
            :search="tableData.search"
            class="elevation-1"
            item-key="ID"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
          >
            <template v-slot:item.is_admin="{ item }">
              <span>
                <v-icon color="green" v-if="item.is_admin == true"
                  >mdi-check-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-minus-circle</v-icon>
              </span>
            </template>
            <template v-slot:item.is_banned="{ item }">
              <span>
                <v-icon color="green" v-if="item.is_banned == true"
                  >mdi-wall</v-icon
                >
                <v-icon color="red" v-else>mdi-minus-circle</v-icon>
              </span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon color="blue">
                <v-icon @click="manageItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">{{
                      $t("COMMON.DIALOG.DELETE_CONFIRM")
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">{{
                        $t("COMMON.DIALOG.ABORT")
                      }}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >{{ $t("COMMON.DIALOG.OK") }}</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  :persistent="true"
                  v-model="dialog_edit"
                  :max-width="single_item_loading ? '300px' : '100%'"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <ManageGroup
                    v-bind:single-item-loading="single_item_loading"
                    :single-item="item_to_edit"
                    v-on:close-dialog="closeManageItem"
                    @dialogLoading="updateDialogLoading"
                  ></ManageGroup>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ManageGroup from "@/view/pages/users/ManageGroup";
import { mapGetters } from "vuex";
import {
  DELETE_MEMBER_GROUP,
  FETCH_MEMBER_GROUPS_LIST,
} from "@/core/services/store/member_groups.module";

export default {
  data() {
    return {
      item_to_edit: {},
      filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      dialog_edit: false,
      single_item_loading: false,
      dialog: false,
      dialogDelete: false,
      itemsPerPage: 10,
      optionsLength: 6,
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [10, 20, 50, 100, -1],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION"),
      },
      // users_list_data: [],
      tableData: {
        search: "",
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.ID"),
            align: "left",
            sortable: true,
            value: "id",
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.GROUP_NAME"),
            value: "group_name",
          },
          { text: this.$t("COMMON.TABLES.COLUMN.IS_ADMIN"), value: "is_admin" },

          {
            text: this.$t("COMMON.TABLES.COLUMN.IS_BANNED"),
            value: "is_banned",
          },
          { text: this.$t("COMMON.TABLES.COLUMN.OPERATION"), value: "action" },
        ],
      },
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["memberGroupsList"]),
    dataTableList() {
      return this.memberGroupsList;
    },
  },
  components: {
    ManageGroup,
  },
  methods: {
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    manageItem(item) {
      this.editedIndex = this.dataTableList.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.item_to_edit = item;
      this.single_item_loading = false;
      this.dialog_edit = true;
    },
    deleteItem(item) {
      this.editedIndex = this.dataTableList.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dataTableList.data.splice(this.editedIndex, 1);
      this.$store.dispatch(DELETE_MEMBER_GROUP, this.editedItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeManageItem() {
      this.dialog_edit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.dataTableList.data[this.editedIndex],
          this.editedItem
        );
      } else {
        this.dataTableList.data.push(this.editedItem);
      }
      this.close();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.MEMBER_GROUPS.AREA") },
      { title: this.$t("PAGES.MEMBER_GROUPS.LIST.TITLE") },
    ]);
    this.$store.dispatch(FETCH_MEMBER_GROUPS_LIST, this.filters);
  },
  watch: {
    dialog_edit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
